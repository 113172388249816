import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import { durationFormat } from "core/utils/string";
import ContentPreview from "core/components/ContentPreview";
import getReferrer from "core/utils/getReferrer";

import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";
import { isTelegramMiniApp } from "core/utils/telegram";
import StarsIcon from "core/components/StarsIcon";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

const PREFIX = "Thumbnail";

const classes = {
  media: `${PREFIX}-media`,
  icon: `${PREFIX}-icon`,
  time: `${PREFIX}-time`,
  live: `${PREFIX}-live`,
  subscribe: `${PREFIX}-subscribe`,
  error: `${PREFIX}-error`,
  errorIcon: `${PREFIX}-errorIcon`,
  premiumButton: `${PREFIX}-premiumButton`,
  premiumButtonInStream: `${PREFIX}-premiumButtonInStream`,
  small: `${PREFIX}-small`,
  topRightCorner: `${PREFIX}-topRightCorner`,
};

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledCardMedia = styled(ThumbnailCardMedia)(({ theme }) => {
  return {
    [`&.${classes.media}`]: {
      width: "100%",
      position: "relative",
      height: 0,
      paddingTop: "56.25%", // 16:9
      backgroundColor:
        theme.palette.mode === "dark" ? "black" : "rgba(0,0,0,0.07)",
      overflow: "hidden",
      borderRadius: 4, // mobile
      backgroundSize: "cover",
      // transition: "background-size ease .25s",
      // [theme.breakpoints.up("sm")]: {
      //   borderRadius: 4, // desktop
      // },
      // "&:hover": {
      //   backgroundSize: "150%",
      // },
    },

    [`& .${classes.time}`]: {
      position: "absolute",
      right: 8,
      bottom: 8,
      backgroundColor: "rgba(0,0,0,0.9)",
      color: "white",
      borderRadius: 4,
      padding: "0px 4px",
    },

    [`& .${classes.subscribe}`]: {
      position: "absolute",
      right: 8,
      top: 8,
    },

    [`& .${classes.error}`]: {
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 2,
      transform: "translate(-50%, -50%)",
    },

    [`& .${classes.errorIcon}`]: {
      marginBottom: theme.spacing(1),
    },

    [`& .${classes.topRightCorner}`]: {
      position: "absolute",
      right: 8,
      top: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 5,
    },

    [`& .${classes.live}`]: {
      // position: "absolute",
      // right: 8,
      // bottom: 8,
      backgroundColor: "#e91e63",
      color: "white",
      borderRadius: 4,
      padding: "0px 4px",
      height: 20,
      lineHeight: "20px",
    },

    [`& .${classes.premiumButton}`]: {
      // position: "absolute",
      // right: 8,
      // top: 8,
      color: "white",
      borderRadius: 4,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    [`& .${classes.premiumButtonInStream}`]: {
      right: 50,
    },

    [`&.${classes.small}`]: {
      [`& .${classes.time}`]: {
        right: 4,
        bottom: 4,
      },
      [`& .${classes.live}`]: {
        right: 4,
        top: 4,
      },
      [`& .${classes.subscribe}`]: {
        right: 4,
        top: 4,
      },
      [`& .${classes.premiumButton}`]: {
        right: 4,
        top: 4,
      },
    },
  };
});

const STARTED = "STARTED";
const PENDING = "PENDING";
const FINISHED = "FINISHED";
const STREAM = "STREAM";
const COLLECTION = "COLLECTION";
const VIDEO = "VIDEO";

function getCanPlay(content, play) {
  let canPlay = play;

  if (content.enableDrm) {
    return false;
  }

  if (content.access.error) {
    canPlay = false;
  }

  if (typeof window !== "undefined") {
    if (content.access.hostnames.length) {
      if (
        !content.access.hostnames.some((hostname) => url.includes(hostname))
      ) {
        canPlay = false;
      }
    }
  }

  if (content.type === STREAM) {
    if (content.streamData.status !== STARTED) {
      canPlay = false;
    }
  }

  if (content.type === COLLECTION || content.premium) {
    canPlay = false;
  }

  return canPlay;
}

const url = getReferrer();

function Thumbnail({ content, play, start, finish, small }) {
  const isStream = content.type === STREAM;

  let canPlay = getCanPlay(content, play);

  const isStarted = content?.streamData?.status === STARTED;
  const displayLiveIcon = isStream && isStarted;
  const displayPremiumIcon = content.premium && !small;

  return (
    <StyledCardMedia
      thumbnails={content.thumbnails}
      className={classNames(classes.media, {
        [classes.small]: small,
      })}
    >
      {canPlay && (
        <ContentPreview
          token={content.access.token}
          title={content.title}
          getIpUrl={content.getIpUrl}
          start={start}
          finish={finish}
        />
      )}

      {!canPlay && content.duration && content.type === VIDEO && (
        <Typography
          className={classes.time}
          variant="caption"
          color="textPrimary"
        >
          {durationFormat(content.duration)}
        </Typography>
      )}

      <div className={classes.topRightCorner}>
        {displayLiveIcon && (
          <Typography
            className={classes.live}
            variant="caption"
            color="textPrimary"
          >
            LIVE
          </Typography>
        )}
        {displayPremiumIcon && (
          <Typography
            classes={{
              root: classNames(classes.premiumButton, {
                // [classes.premiumButtonInStream]: displayLiveIcon,
              }),
            }}
            variant="caption"
            color="textPrimary"
            sx={{
              bgcolor: content.viewerPurchase
                ? "background.body"
                : "primary.main",
            }}
          >
            {isTelegramMiniApp ? (
              <>
                {content.viewerPurchase ? (
                  <ShoppingBasketIcon sx={{ fontSize: "16px" }} />
                ) : (
                  <StarsIcon sx={{ fontSize: "12px" }} />
                )}
              </>
            ) : (
              <>
                {content.viewerPurchase ? (
                  <LockOpenIcon sx={{ fontSize: "16px" }} />
                ) : (
                  <LockIcon sx={{ fontSize: "16px" }} />
                )}
              </>
            )}
          </Typography>
        )}
      </div>
    </StyledCardMedia>
  );
}

Thumbnail.propTypes = {
  play: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    getIpUrl: PropTypes.string,
    duration: PropTypes.number,
    type: PropTypes.oneOf([STREAM, VIDEO, COLLECTION]).isRequired,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    access: PropTypes.shape({
      token: PropTypes.string,
      error: PropTypes.string,
      hostnames: PropTypes.arrayOf(PropTypes.string),
    }),
    video: PropTypes.shape({
      id: PropTypes.string.isRequired,
      duration: PropTypes.number,
    }),
    streamData: PropTypes.shape({
      status: PropTypes.oneOf([STARTED, PENDING, FINISHED]).isRequired,
    }),
    thumbnails: PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
        url: PropTypes.string.isRequired,
        webpUrl: PropTypes.string,
      })
    ),

    childrenContentsCount: PropTypes.number,
  }),
  start: PropTypes.number,
  finish: PropTypes.number,
  small: PropTypes.bool,
};

export default React.memo(Thumbnail);
