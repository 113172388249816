import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ProductPayment from "arena/components/ProductPayment";
import ProductPurchase from "arena/components/ProductPurchase";
import { alpha } from "@mui/system";
import { useTranslation } from "react-i18next";
import renderEventDate from "core/utils/renderEventDate";
import Chip from "@mui/material/Chip";
import ProductDescription from "./ProductDescription";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";

const PREFIX = "ProductCover";

const classes = {
  root: `${PREFIX}-root`,
  mask: `${PREFIX}-mask`,
  avatar: `${PREFIX}-avatar`,
  title: `${PREFIX}-title`,
  text: `${PREFIX}-text`,
  inner: `${PREFIX}-inner`,
  content: `${PREFIX}-content`,
  headerContent: `${PREFIX}-headerContent`,
  footerContent: `${PREFIX}-footerContent`,
  description: `${PREFIX}-description`,
};

const Root = styled(Box)(({ theme }) => {
  return {
    // overflow: "hidden",
    // minHeight: "40vh",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    boxSizing: "border-box",
    // marginTop: -64,
    // marginBottom: -100,
    // padding: "80px 16px 100px",
    // paddingLeft: 2,
    // paddingRight: 2,
    padding: "0px 16px 0px",

    [theme.breakpoints.up("sm")]: {
      padding: "0px 24px 0px",
      // paddingLeft: 2,
      // paddingRight: 2,
    },

    [`& .${classes.mask}`]: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.7)",
      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        height: 200,
        pointerEvents: "none",
        // backgroundImage:
        //   "linear-gradient(transparent, rgba(16,16,16,0.1), rgba(16,16,16,0.8), #101010)",
        backgroundImage: `linear-gradient(transparent, ${alpha(
          theme.palette.background.body,
          0.1
        )}, ${alpha(theme.palette.background.body, 0.5)}, ${
          theme.palette.background.body
        })`,
      },
    },

    [`& .${classes.avatar}`]: {
      width: 60,
      height: 60,
    },

    [`& .${classes.title}`]: {
      fontWeight: 800,
      color: "white",
      textShadow: "0px 0px 20px black",
      fontSize: "1.8rem",

      [theme.breakpoints.up("sm")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "3rem",
      },
    },

    [`& .${classes.description}`]: {
      "& :first-of-type": {
        marginTop: 0,
      },
      "& :last-of-type": {
        marginBottom: 8,
      },
    },

    [`& .${classes.inner}`]: {
      position: "relative",
      width: "100%",
      // overflow: "hidden",
    },
  };
});

export default function ProductCover({
  closeRouteDialogButton,
  promocodeIds,
  setPromocodeIds,
  viewer,
  embed,
  product,
  loading,
  onClickHelpButton,
  onClickPriceButton,
  refetch,
}) {
  const { t } = useTranslation("arena");

  if (loading && !product) {
    return null;
  }

  const viewerPurchase = product?.viewerPurchase;
  const showProductPayment = !viewerPurchase;
  const showProductPurchase = !!viewerPurchase && !product?.finished;
  const eventTime = renderEventDate({
    eventStartAt: product.eventStartAt,
    eventFinishAt: product.eventFinishAt,
    t,
  });

  return (
    <Root>
      {/* <div className={classes.mask} /> */}
      <div className={classes.inner}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} lg={5}>
            {showProductPayment && (
              <ProductPayment
                promocodeIds={promocodeIds}
                setPromocodeIds={setPromocodeIds}
                viewer={viewer}
                embed={embed}
                product={product}
                onClickHelpButton={onClickHelpButton}
                onClickPriceButton={onClickPriceButton}
                refetch={refetch}
                loading={loading}
                withCloseRouteDialogButton={closeRouteDialogButton}
              />
            )}

            {showProductPurchase && (
              <ProductPurchase
                product={product}
                closeRouteDialogButton={closeRouteDialogButton}
              />
            )}
          </Grid>
          <Grid item xs={12} md={7} lg={7}>
            <Card
              elevation={0}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <AppBar
                color="default"
                elevation={0}
                position="static"
                // sx={{ backgroundColor: "rgba(0,0,0,.25)" }}
              >
                <CardHeader
                  title={t("productCover.title")}
                  action={
                    <IconButton onClick={onClickHelpButton} aria-label="Help">
                      <HelpIcon />
                    </IconButton>
                  }
                />
              </AppBar>

              {eventTime && (
                <CardContent sx={{ paddingBottom: "0 !important" }}>
                  <Chip label={eventTime} />
                </CardContent>
              )}

              {product.description && (
                <ProductDescription
                  viewerPurchase={viewerPurchase}
                  product={product}
                  className={classes.description}
                />
              )}
            </Card>
          </Grid>

          {/* {!viewerPurchase && (
            <Grid item xs={12}>
              <ProductIcons />
            </Grid>
          )} */}
        </Grid>
        {!viewerPurchase && closeRouteDialogButton}
      </div>
    </Root>
  );
}

ProductCover.propTypes = {
  closeRouteDialogButton: PropTypes.node,
  onClickPriceButton: PropTypes.func,
  embed: PropTypes.bool,
  setPayment: PropTypes.func,
  viewer: PropTypes.object,
  promocodeIds: PropTypes.array,
  setPromocodeIds: PropTypes.func.isRequired,
  onClickHelpButton: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    video: PropTypes.object,
    description: PropTypes.string,
    html: PropTypes.string,
    shareUrl: PropTypes.string.isRequired,
    publishAt: PropTypes.string,
    eventStartAt: PropTypes.string,
    eventFinishAt: PropTypes.string,
    startAt: PropTypes.string,
    finishAt: PropTypes.string,
    sellerInfo: PropTypes.string,
    finished: PropTypes.bool,
    started: PropTypes.bool,
    viewerPurchase: PropTypes.object,
    // backgroundBanner: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     format: PropTypes.string.isRequired,
    //     url: PropTypes.string.isRequired,
    //   })
    // ),
    rightholder: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      sellerInfo: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    }),
    viewerPayment: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  refetch: PropTypes.func,
  loading: PropTypes.bool,
};
