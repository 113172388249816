import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { ArenaBannerQuery } from "arena/queries";

import { getBannerSrcSet } from "core/utils/getSrcSet";

function getObjectPosition(
  horizontalAlign = "center",
  verticalAlign = "center"
) {
  return [horizontalAlign, verticalAlign].join(" ");
}

const StyledPicture = styled("picture", {
  shouldForwardProp: (prop) =>
    prop !== "horizontalAlign" &&
    prop !== "verticalAlign" &&
    prop !== "background",
})(({ theme, background, horizontalAlign, verticalAlign }) => {
  const style = {
    zIndex: -1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    display: "flex",

    "& img": {
      display: "block",
      objectPosition: getObjectPosition(
        horizontalAlign?.mobile,
        verticalAlign?.mobile
      ),
      objectFit: "cover",
      height: "auto",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        objectPosition: getObjectPosition(
          horizontalAlign?.desktop,
          verticalAlign?.desktop
        ),
      },
    },
  };

  if (background) {
    if ("brightness" in background) {
      if (theme.palette.mode === "dark") {
        style["filter"] = `brightness(${background?.brightness})`;
      } else {
        style["filter"] = `opacity(${background?.brightness})`;
      }
    }
  }

  return style;
});

function useBanner(bannerId) {
  const { data } = useQuery(ArenaBannerQuery, {
    skip: !bannerId,
    variables: {
      id: bannerId,
    },
  });

  return data?.banner;
}

const MAXRES = "MAXRES";
export default function BackgroundImage({
  background,
  horizontalAlign,
  verticalAlign,
}) {
  const banner = useBanner(background?.desktopImageId);
  const bannerMobile = useBanner(background?.mobileImageId);

  let defaultBanner = null;
  let bannerSrcSet = null;
  let webpSrcSet = null;

  if (banner) {
    bannerSrcSet = getBannerSrcSet(banner);
    defaultBanner = banner.find((b) => b.format === MAXRES)?.url;
  }

  let bannerMobileSrcSet = null;
  let webpMobileSrcSet = null;
  if (bannerMobile) {
    bannerMobileSrcSet = getBannerSrcSet(bannerMobile);
    if (!defaultBanner) {
      defaultBanner = bannerMobile.find((b) => b.format === MAXRES)?.url;
    }
  }

  if (!defaultBanner) return null;

  return (
    <StyledPicture
      background={background}
      horizontalAlign={horizontalAlign}
      verticalAlign={verticalAlign}
    >
      {webpMobileSrcSet && (
        <source
          media="(max-width: 599px)" // for mobile
          srcSet={webpMobileSrcSet}
          type="image/webp"
        />
      )}

      {webpSrcSet && (
        <source
          media="(min-width: 600px)" // for desktop
          srcSet={webpSrcSet}
          type="image/webp"
        />
      )}

      {bannerMobileSrcSet && (
        <source
          media="(max-width: 599px)" // for mobile
          srcSet={bannerMobileSrcSet}
        />
      )}

      {bannerSrcSet && (
        <source
          media="(min-width: 600px)" // for desktop
          srcSet={bannerSrcSet}
        />
      )}

      <img
        src={defaultBanner}
        srcSet={bannerSrcSet || bannerMobileSrcSet}
        alt=""
      />
    </StyledPicture>
  );
}

BackgroundImage.propTypes = {
  horizontalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  verticalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  background: PropTypes.shape({
    brightness: PropTypes.number,
    desktopImageId: PropTypes.string,
    mobileImageId: PropTypes.string,
  }),
  bannerSrcSet: PropTypes.string,
  bannerMobileSrcSet: PropTypes.string,
  defaultBanner: PropTypes.string,
};
