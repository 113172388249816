import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { durationFormat } from "core/utils/string";

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  marginLeft: 10,
  fontSize: "0.75rem",

  color: "rgb(255, 255, 255);",

  ["@media (min-width: 320px)"]: {
    display: "flex",
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: "0.875rem",
  },
}));

export default function Duration({ videoState }) {
  if (!videoState) return null;
  if (videoState.live) return null;

  const { duration, currentTime } = videoState;

  return (
    <StyledTypography component="span" variant="body2" noWrap>
      {durationFormat(currentTime)} / {durationFormat(duration)}
    </StyledTypography>
  );
}

Duration.propTypes = {
  videoState: PropTypes.object,
};
