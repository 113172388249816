import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";

import Link from "@mui/material/Link";

const PREFIX = "PoweredBy";

const classes = {
  root: `${PREFIX}-root`,
  poweredBy: `${PREFIX}-poweredBy`,
  poweredByLink: `${PREFIX}-poweredByLink`,
  poweredByImage: `${PREFIX}-poweredByImage`,
};

const Root = styled("div")(() => ({
  [`& .${classes.poweredBy}`]: {
    textAlign: "center",
  },
  [`&.${classes.root}`]: {},

  [`& .${classes.poweredBy}`]: {
    textAlign: "center",
  },

  [`& .${classes.poweredByImage}`]: {
    position: "absolute",
    opacity: 0.7,
    transition: "all .15s ease-in-out",
  },
  [`& .${classes.poweredByImageLogo}`]: {},

  [`& .${classes.poweredByLink}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": {
      [`& .${classes.poweredByImage}`]: {
        opacity: 1,
      },
    },
  },
}));

export default function PoweredBy({ arena }) {
  const theme = useTheme();
  if (arena.isTelegramArena) {
    return (
      <Root className={classes.root}>
        <Link
          target="_blank"
          underline="none"
          className={classes.poweredByLink}
          href={`http://t.me/ViGram_App`}
        >
          <img
            className={classes.poweredByImageLogo}
            src="https://st-mvs-wtf.akamaized.net/image/poweredby/Vigram_icon.svg"
          />
          <img
            className={classes.poweredByImage}
            src={
              theme.palette.mode === "dark"
                ? "https://st-mvs-wtf.akamaized.net/image/poweredby/Vigram_text_powered_white.svg"
                : "https://st-mvs-wtf.akamaized.net/image/poweredby/Vigram_text_powered_black.svg"
            }
          />
        </Link>
      </Root>
    );
  }

  return (
    <Root className={classes.root}>
      <Link
        target="_blank"
        underline="none"
        className={classes.poweredByLink}
        href={`http://sprintty.com/?utm_source=${arena.title}&utm_campaign=footer_link`}
      >
        <img
          className={classes.poweredByImageLogo}
          src="https://st-mvs-wtf.akamaized.net/image/poweredby/logo_new.svg"
        />
        <img
          className={classes.poweredByImage}
          src="https://st-mvs-wtf.akamaized.net/image/poweredby/grey_new.svg"
        />
      </Link>
    </Root>
  );
}

PoweredBy.propTypes = {
  arena: PropTypes.shape({
    isTelegramArena: PropTypes.bool,
    title: PropTypes.string.isRequired,
  }).isRequired,
};
