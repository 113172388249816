import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useQuery } from "@apollo/client";
import { TagQuery } from "arena/queries";
import TagHelmet from "./TagHelmet";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "arena/components/NoMatchScreen";
import useScrollToTop from "core/hooks/useScrollToTop";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";
// import Grid from "@mui/material/Grid";
// import Chip from "@mui/material/Chip";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";
import HomepageTitle from "arena/components/HomepageTitle";
import ArenaPosts from "arena/components/ArenaPosts";
import ArenaDigitalAssets from "arena/components/ArenaDigitalAssets";
import ArenaContents from "arena/components/ArenaContents";

const Text = styled(Typography)({
  "& img": {
    maxWidth: "100%",
  },
});

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 3),
  },
}));

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(({ theme }) => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
}));

export default function Tag() {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation("arena");
  const { tagId } = params;

  const onCompleted = (data) => {
    if (data.tag) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.tag.title,
      });
    }
  };

  const { data, loading, error } = useQuery(TagQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: tagId,
    },
    onCompleted,
  });

  const tag = data?.tag;

  useScrollToTop();

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!tag && !loading) {
    return <NoMatchScreen />;
  }

  return (
    <Root>
      {tag && <TagHelmet tag={tag} />}

      {tag?.image || tag?.html ? (
        <Container maxWidth="md" sx={{ p: 0 }}>
          <Card sx={{ backgroundColor: "rgba(255,255,255,0.03)" }}>
            {tag.image && (
              <StyledThumbnailCardMedia
                thumbnails={tag.image.thumbnails}
                alt={tag.title}
                size="MAXRES"
              />
            )}

            <CardHeader title={tag.title} />

            {tag.html && (
              <CardContent sx={{ pt: 0 }}>
                <Text
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: tag.html }}
                />
              </CardContent>
            )}
          </Card>
        </Container>
      ) : (
        <HomepageTitle title={tag?.title} />
      )}

      <ArenaPosts
        title={t("posts")}
        view="CAROUSEL"
        variant="TITLE_OUTSIDE"
        tagIds={[tagId]}
        loadMore
      />

      <ArenaDigitalAssets
        title={t("digitalAssets")}
        view="CAROUSEL"
        variant="TITLE_OUTSIDE"
        tagIds={[tagId]}
        loadMore
      />

      <ArenaContents
        view="GRID"
        sort={"LATEST"}
        limit={12}
        title={t("contents.videos")}
        type={"VIDEO"}
        tagIds={[tagId]}
        noMore
        loadMore
        inView
      />

      <ArenaContents
        view="GRID"
        sort={"LATEST"}
        limit={12}
        title={t("contents.streams")}
        type={"STREAM"}
        tagIds={[tagId]}
        noMore
        loadMore
        inView
      />

      <ArenaContents
        view="GRID"
        sort={"LATEST"}
        limit={12}
        title={t("contents.collections")}
        type={"COLLECTION"}
        tagIds={[tagId]}
        noMore
        loadMore
        inView
      />
    </Root>
  );
}

Tag.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tagId: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
