import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import { VideoFileQuery } from "arena/queries";
import { alpha } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import BackgroundVideo from "./BackgroundVideo";
import BackgroundImage from "./BackgroundImage";
import CoverButton from "./CoverButton";
import Text from "./Text";
import Inner from "./Inner";

const PREFIX = "ArenaCustomCover";

const classes = {
  innerGrid: `${PREFIX}-innerGrid`,
};

const horizontalAlignToFlex = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};

const verticalAlignToFlex = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
};

const Root = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "horizontalAlign" && prop !== "verticalAlign",
})(({ theme, horizontalAlign, verticalAlign }) => ({
  overflow: "hidden",
  position: "relative",
  height: "100vh",
  boxSizing: "border-box",
  display: "flex",
  marginBottom: -100,

  padding: "80px 16px 120px",
  alignItems: verticalAlignToFlex[verticalAlign?.mobile], // vertical align
  justifyContent: horizontalAlignToFlex[horizontalAlign?.mobile], // horizontal align
  marginTop: -54,

  [theme.breakpoints.up("sm")]: {
    padding: "100px 16px 160px",
    alignItems: verticalAlignToFlex[verticalAlign?.desktop],
    justifyContent: horizontalAlignToFlex[horizontalAlign?.desktop],
    marginTop: -64,
  },

  [theme.breakpoints.up("md")]: {
    paddingLeft: 166,
    paddingRight: 166,
  },

  // Затемнение
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 200,
    pointerEvents: "none",
    backgroundImage: `linear-gradient(transparent, ${alpha(
      theme.palette.background.body,
      0.1
    )}, ${alpha(theme.palette.background.body, 0.5)}, ${
      theme.palette.background.body
    })`,
  },

  [`& .${classes.innerGrid}`]: {
    justifyContent: horizontalAlignToFlex[horizontalAlign?.mobile],
    [theme.breakpoints.up("sm")]: {
      justifyContent: horizontalAlignToFlex[horizontalAlign?.desktop],
    },
  },
}));

function useVideoFile(videoFileId) {
  const { data } = useQuery(VideoFileQuery, {
    skip: !videoFileId,
    variables: {
      id: videoFileId,
    },
  });

  return data?.videoFile;
}

export default function ArenaCustomCover({
  title,
  subTitle,
  buttons,
  background,
  align,
  alignMobile,
  verticalAlign: _verticalAlign,
  brightness,
  buttonsColor,
  buttonsSize,
}) {
  const desktopVideo = useVideoFile(background?.desktopVideoId);
  const mobileVideo = useVideoFile(background?.mobileVideoId);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const backgroundVideo = md ? desktopVideo : mobileVideo || desktopVideo;

  const horizontalAlign = {
    desktop: align || "center",
    mobile: alignMobile || "center",
  };

  const verticalAlign = {
    desktop: _verticalAlign?.desktop || "center",
    mobile: _verticalAlign?.mobile || "center",
  };

  return (
    <Root
      test-id="ArenaCustomCover"
      horizontalAlign={horizontalAlign}
      verticalAlign={verticalAlign}
    >
      <BackgroundImage
        background={{
          ...background,
          brightness,
        }}
        horizontalAlign={horizontalAlign}
        verticalAlign={verticalAlign}
      />

      <BackgroundVideo
        backgroundVideo={backgroundVideo}
        background={{
          ...background,
          brightness,
        }}
      />

      <Inner horizontalAlign={horizontalAlign} verticalAlign={verticalAlign}>
        <Grid
          container
          spacing={3}
          className={classes.innerGrid}
          alignItems="center"
        >
          <Grid item xs={12}>
            {title?.text && (
              <Text {...title} horizontalAlign={horizontalAlign} gutterBottom />
            )}
            {subTitle?.text && (
              <Text {...subTitle} horizontalAlign={horizontalAlign} />
            )}
          </Grid>

          {buttons
            .filter((button) => button.text && !button.disabled)
            .map((button, index) => (
              <Grid item key={index}>
                <CoverButton
                  button={button}
                  buttonColor={buttonsColor}
                  buttonSize={buttonsSize}
                />
              </Grid>
            ))}
        </Grid>
      </Inner>
    </Root>
  );
}
ArenaCustomCover.defaultProps = {
  buttons: [],
};

ArenaCustomCover.propTypes = {
  title: PropTypes.shape({
    weight: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  subTitle: PropTypes.shape({
    weight: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  verticalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  align: PropTypes.string,
  alignMobile: PropTypes.string,
  brightness: PropTypes.number,
  background: PropTypes.shape({
    desktopImageId: PropTypes.string,
    mobileImageId: PropTypes.string,
    desktopVideoId: PropTypes.string,
    mobileVideoId: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  arena: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  productId: PropTypes.string,
  buttonsColor: PropTypes.string,
  buttonsSize: PropTypes.string,
};
