import {
  responsiveFontSizes,
  createTheme as createMuiTheme,
  alpha,
  darken,
  lighten,
  rgbToHex,
} from "@mui/material/styles";
import { postEvent } from "@telegram-apps/sdk";
import { isTelegramMiniApp } from "core/utils/telegram";

export function createTheme(customTheme) {
  const modes = {
    DARK: "dark",
    LIGHT: "light",
  };

  const backgroundColors = {
    dark: "#0D0D0D",
    light: "#dcdcdc",
  };

  const mode = modes[customTheme?.palette?.type] || modes.DARK;
  const backgroundColor =
    customTheme?.palette?.backgroundColor || backgroundColors[mode];

  const backgroundHelper = mode === "dark" ? lighten : darken;

  const headerColor =
    mode === "dark" ? "rgba(255, 255, 255, 0.03)" : "rgba(0, 0, 0, 0.03)";

  // backgroundHelper(
  //   backgroundColor,
  //   mode === "dark" ? 0.04 : 0.11
  // );

  const paperColor =
    mode === "dark"
      ? backgroundHelper(backgroundColor, 0.085)
      : backgroundHelper(backgroundColor, 0.085);

  const textColor = mode === "dark" ? "rgb(255, 255, 255)" : "rgba(0, 0, 0)";

  const primaryTextColor = alpha(textColor, 0.9);
  const secondaryTextColor = alpha(textColor, 0.7);

  console.log({
    backgroundColor,
    headerColor,
    paperColor,
    textColor,
    primaryTextColor,
    secondaryTextColor,
  });

  const theme = {
    palette: {
      mode,

      primary: {
        main: customTheme?.palette?.primary || "#e91e63",
      },
      secondary: {
        main: customTheme?.palette?.secondary || "#3ea6ff",
      },

      background: {
        body: backgroundColor,
        header: headerColor,

        paper: paperColor,
      },

      text: {
        primary: primaryTextColor,
        secondary: secondaryTextColor,
        primaryInHex: rgbToHex(primaryTextColor),
      },
    },

    typography: {
      // fontSize: 30,

      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 500,
      },

      fontFamily:
        customTheme?.typography?.fontFamily ||
        [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: "100%",
          },
          body: {
            height: "100%",
            backgroundColor: backgroundColor,
            WebkitTextSizeAdjust: "none",
            // overscrollBehaviorY: "none",
            "& #root": {
              height: "100%",
            },
          },
          a: {
            color: "#3ea6ff",
            textDecoration: "none",
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          backdrop: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        },
      },
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            backgroundColor: paperColor,
          },
        },
      },

      // .css-1ww2xic-MuiPaper-root-MuiPickersPopper-paper

      MuiButton: {
        variants: [
          {
            props: { variant: "outlined-product" },
            style: {
              border: `4px solid`,
              borderColor: customTheme?.palette?.primary || "#e91e63",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
        ],
      },

      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            backgroundColor: paperColor,
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          // elevation: 1,
        },
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor: headerColor,
            // "&.MuiPaper-root": {
            //   backgroundColor: headerColor,
            // },
          },
          colorDefault: {
            backgroundColor: headerColor,
          },
          colorPrimary: {
            backgroundColor: headerColor,
          },
          colorSecondary: {
            backgroundColor: headerColor,
          },
          colorInherit: {
            backgroundColor: headerColor,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            borderRadius: 12,
            backgroundColor: paperColor,
            "&.MuiPaper-root": {
              backgroundColor: paperColor,
            },
          },
        },
      },
      MuiDialog: {
        defaultProps: {
          PaperProps: {
            elevation: 0,
          },
        },
        styleOverrides: {
          paper: {
            backgroundColor: paperColor,
          },
        },
      },

      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: paperColor,
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          colorTextSecondary: {
            color: secondaryTextColor,
          },
        },
      },

      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: paperColor,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              background: "transparent",
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "initial",
            minWidth: "0 !important",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            touchAction: "none",
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: customTheme?.palette?.backgroundColor,
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          componentsProps: {
            paper: {
              elevation: 2,
            },
          },
        },
        styleOverrides: {
          paper: {
            backgroundColor: backgroundColor,
            backdropFilter: "blur(10px)",
            boxShadow:
              "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
          },
        },
      },
    },
  };

  if (isTelegramMiniApp) {
    const bgcolor = backgroundColor;
    postEvent("web_app_set_header_color", { color: bgcolor }); // eslint-disable-line camelcase
    postEvent("web_app_set_background_color", { color: bgcolor }); // eslint-disable-line camelcase
  }

  return responsiveFontSizes(createMuiTheme(theme));
}
