import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { styled } from "@mui/material/styles";

const PREFIX = "ArenaCoverText";

const classes = {
  textShadow: `${PREFIX}-textShadow`,
  header1: `${PREFIX}-header1`,
  header2: `${PREFIX}-header2`,
  header3: `${PREFIX}-header3`,
  subtitle1: `${PREFIX}-subtitle1`,
  subtitle2: `${PREFIX}-subtitle2`,
  subtitle3: `${PREFIX}-subtitle3`,
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "horizontalAlign",
})(({ theme, horizontalAlign }) => ({
  textAlign: horizontalAlign?.mobile,

  [theme.breakpoints.up("sm")]: {
    textAlign: horizontalAlign?.desktop,
  },

  [`&.${classes.textShadow}`]: {
    textShadow:
      theme.palette.mode === "dark"
        ? "0px 0px 30px rgba(0,0,0,0.6)"
        : "0px 0px 30px rgba(255,255,255,0.8)",
  },

  [`&.${classes.textShadowLight}`]: {
    textShadow:
      theme.palette.mode === "dark"
        ? "0px 0px 30px rgba(0,0,0,0.6)"
        : "0px 0px 30px rgba(255,255,255,0.8)",
  },

  [`&.${classes.header1}`]: {
    fontSize: "3rem",
    fontWeight: "800",
    lineHeight: "1.1",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "4.5rem",
    },
  },

  [`&.${classes.header2}`]: {
    fontSize: "2.5rem",
    fontWeight: "800",
    lineHeight: "1.1",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "4rem",
    },
  },

  [`&.${classes.header3}`]: {
    fontSize: "2rem",
    fontWeight: "800",
    lineHeight: "1.1",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3.5rem",
    },
  },

  [`&.${classes.subtitle1}`]: {
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.3",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem",
    },
  },

  [`&.${classes.subtitle2}`]: {
    fontSize: "0.9rem",
    fontWeight: "400",
    lineHeight: "1.4",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.2rem",
    },
  },

  [`&.${classes.subtitle3}`]: {
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "1.5",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.1rem",
    },
  },
}));

export default function Text({
  color,
  text,
  variant,
  horizontalAlign,
  gutterBottom,
}) {
  return (
    <StyledTypography
      horizontalAlign={horizontalAlign}
      color={color}
      gutterBottom={gutterBottom}
      className={classNames(classes.title, {
        [classes.textShadow]: color === "textPrimary",
        [classes.textShadowLight]: color === "textSecondary",
        [classes.header1]: variant === "header1",
        [classes.header2]: variant === "header2",
        [classes.header3]: variant === "header3",
        [classes.subtitle1]: variant === "subtitle1",
        [classes.subtitle2]: variant === "subtitle2",
        [classes.subtitle3]: variant === "subtitle3",
      })}
    >
      {text}
    </StyledTypography>
  );
}

Text.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  gutterBottom: PropTypes.bool,
  horizontalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
};
