import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const PREFIX = "ArenaCustomCoverText";

const classes = {
  textShadow: `${PREFIX}-textShadow`,
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "horizontalAlign",
})(({ theme, horizontalAlign }) => ({
  textAlign: horizontalAlign?.mobile,

  [theme.breakpoints.up("sm")]: {
    textAlign: horizontalAlign?.desktop,
  },

  [`&.${classes.textShadow}`]: {
    textShadow:
      theme.palette.mode === "dark"
        ? "0px 0px 30px rgba(0,0,0,0.8)"
        : "0px 0px 30px rgba(255,255,255,0.8)",
  },
}));

export default function Text({
  color,
  text,
  weight,
  size,
  horizontalAlign,
  gutterBottom,
}) {
  return (
    <StyledTypography
      horizontalAlign={horizontalAlign}
      gutterBottom={gutterBottom}
      variant={size}
      fontWeight={weight}
      color={color}
      className={classNames(classes.title, classes.textShadow)}
    >
      {text}
    </StyledTypography>
  );
}

Text.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
  gutterBottom: PropTypes.bool,
  horizontalAlign: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
};
