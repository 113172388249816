import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import Link from "arena/components/Link";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { truncate } from "core/utils/string";

import { isTelegramMiniApp } from "core/utils/telegram";
import StarsIcon from "core/components/StarsIcon";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";

const PREFIX = "ContentGridItem";

const classes = {
  avatar: `${PREFIX}-avatar`,
  cardActionArenaInside: `${PREFIX}-cardActionArenaInside`,
  mediaLink: `${PREFIX}-mediaLink`,
  media: `${PREFIX}-media`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardHeaderInside: `${PREFIX}-cardHeaderInside`,
  cardMediaInside: `${PREFIX}-cardMediaInside`,
  link: `${PREFIX}-link`,
  linkSmall: `${PREFIX}-linkSmall`,
  premiumButton: `${PREFIX}-premiumButton`,
};

const Root = styled(Card)(({ theme }) => ({
  width: "100%",

  [`& .${classes.premiumButton}`]: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "white",
    borderRadius: 4,
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.mediaLink}`]: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "inherit",
    // paddingBottom: 8,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 4, // desktop
    },
  },

  // [`& .${classes.cardActionArenaInside}`]: {
  //   paddingBottom: 0,
  // },

  [`& .${classes.cardHeader}`]: {
    width: "100%",
    alignItems: "flex-start",
    // padding: "16px 0",
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: theme.spacing(0),
    //   paddingRight: theme.spacing(0),
    // },
  },

  [`& .${classes.cardHeaderInside}`]: {
    position: "absolute",
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    fontWeight: 500,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontSize: "0.9rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.1rem",
    },
  },

  [`& .${classes.linkSmall}`]: {
    fontSize: "0.9rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.9rem",
    },
  },
}));

const DEFAULT = "DEFAULT";
const HIGH = "HIGH";
const MAXRES = "MAXRES";
const MEDIUM = "MEDIUM";

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(({ theme }) => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: theme.palette.mode === "dark" ? "black" : "rgba(0,0,0,0.07)",
  overflow: "hidden",
  borderRadius: 0, // mobile
  [theme.breakpoints.up("sm")]: {
    borderRadius: 4, // desktop
  },
  [`&.${classes.cardMediaInside}`]: {
    position: "relative",
    display: "flex",
    alignItems: "end",
  },
  [`&.${classes.cardMediaInside}:before`]: {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    pointerEvents: "none",
    backgroundImage:
      theme.palette.mode === "dark"
        ? `linear-gradient(rgba(0,0,0,0.2),  #000)`
        : `linear-gradient(rgba(255,255,255,0.2),  #fff)`,
  },
}));

export default function Item({ post, smallTitle, variant, showDescription }) {
  const { t } = useTranslation("arena");

  return (
    <Root>
      <CardActionArea
        component={Link}
        to={post.url}
        className={classNames(classes.mediaLink, {
          [classes.cardActionArenaInside]: variant === "TITLE_INSIDE",
        })}
      >
        <StyledThumbnailCardMedia
          className={classNames({
            [classes.cardMediaInside]: variant === "TITLE_INSIDE",
          })}
          thumbnails={post?.image?.thumbnails}
        >
          {post.premium && (
            <Typography
              classes={{
                root: classNames(classes.premiumButton),
              }}
              variant="caption"
              color="textPrimary"
              sx={{
                bgcolor: post.viewerPurchase
                  ? "background.body"
                  : "primary.main",
              }}
            >
              {isTelegramMiniApp ? (
                <>
                  {post.viewerPurchase ? (
                    <ShoppingBasketIcon sx={{ fontSize: "16px" }} />
                  ) : (
                    <StarsIcon sx={{ fontSize: "13px" }} />
                  )}
                </>
              ) : (
                <>
                  {post.viewerPurchase ? (
                    <LockOpenIcon sx={{ fontSize: "16px" }} />
                  ) : (
                    <LockIcon sx={{ fontSize: "16px" }} />
                  )}
                </>
              )}
            </Typography>
          )}

          {variant === "TITLE_INSIDE" && (
            <CardHeader
              className={classNames({
                [classes.cardHeader]: true,
                [classes.cardHeaderInside]: true,
              })}
              title={truncate(post.title, smallTitle ? 50 : 80)}
              subheader={t("publishAt", { date: post.publishAt })}
              titleTypographyProps={{
                color: "textPrimary",
                className: classNames({
                  [classes.link]: true,
                  [classes.linkSmall]: smallTitle,
                }),
              }}
              subheaderTypographyProps={{
                variant: "caption",
                color: "textSecondary",
              }}
            />
          )}
        </StyledThumbnailCardMedia>

        {variant === "TITLE_OUTSIDE" && (
          <>
            <CardHeader
              className={classes.cardHeader}
              title={post.title}
              subheader={
                showDescription ? (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: post.description,
                    }}
                  />
                ) : null
              }
              titleTypographyProps={{
                gutterBottom: true,
                className: classNames({
                  [classes.link]: !smallTitle,
                  [classes.linkSmall]: smallTitle,
                }),
              }}
              subheaderTypographyProps={{
                variant: "body2",
                color: "textSecondary",
              }}
            />

            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ pl: 2, pr: 2, pb: 2, mt: "auto" }}
            >
              {t("publishAt", { date: post.publishAt })}
            </Typography>
          </>
        )}
      </CardActionArea>
    </Root>
  );
}

Item.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    publishAt: PropTypes.string.isRequired,
    premium: PropTypes.bool,
    viewerPurchase: PropTypes.object,
    image: PropTypes.shape({
      url: PropTypes.string,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          format: PropTypes.oneOf([DEFAULT, MEDIUM, HIGH, MAXRES]).isRequired,
          url: PropTypes.string.isRequired,
          webpUrl: PropTypes.string,
        })
      ),
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
  showDescription: PropTypes.bool,
  variant: PropTypes.oneOf(["TITLE_INSIDE", "TITLE_OUTSIDE"]),
  smallTitle: PropTypes.bool,
};
