import React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const PREFIX = "LoadingScreen";

const classes = {
  progress: `${PREFIX}-progress`,
};

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [`& .${classes.progress}`]: {
    color: theme.palette.mode === "dark" ? "white" : "rgba(0, 0, 0, 0.6)",
  },
}));

export default function LoadingScreen() {
  return (
    <Root className={classes.root}>
      <CircularProgress className={classes.progress} />
    </Root>
  );
}
