import React, {
  useMemo,
  cloneElement,
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import PropTypes from "prop-types";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
// import Slide from "@mui/material/Slide";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isTelegramMiniApp } from "core/utils/telegram";
import { useMatch } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const RouteDialogContext = createContext();

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.2)"
      : "rgba(255, 255, 255, 0.5)",
  position: "absolute",
  top: 10,
  zIndex: 10000,
  "&:hover": {
    background:
      theme.palette.mode === "dark"
        ? "rgba(0, 0, 0, 0.3)"
        : "rgba(255, 255, 255, 0.6)",
  },
}));

export default function RouteDialog({ path, element }) {
  const match = useMatch(path);
  const [lastMatch, setLastMatch] = useState(match);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (match) {
      setLastMatch(match);
    }
  }, [match]);

  const open = Boolean(match);

  const clonedElement = useMemo(
    () => cloneElement(element, lastMatch),
    [element, lastMatch]
  );

  const onClose = useCallback(() => {
    if (location?.state?.background) {
      /**
       * идем назад, не в background
       * так как это могла быть цепочка диалогов
       * продукт => контент
       */
      navigate(
        -1
        // location.state.background.pathname + location.state.background.search
      );
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (!process.browser) return;

    const routeDialogScrollable = document.querySelector(
      "#route-dialog-scrollable"
    );

    routeDialogScrollable?.scrollTo(0, 0);
  }, [location.pathname]);

  const contextValue = {
    onClose,
  };

  return (
    <SwipeableDrawer
      test-id={open ? "route-dialog-open" : "route-dialog-closed"}
      open={open}
      anchor="bottom"
      onOpen={() => {
        console.log("onOpen");
      }}
      onClose={onClose}
      elevation={0}
      disableSwipeToOpen
      disableDiscovery
      // hideBackdrop
      disablePortal // это нужно чтобы в миниапе не прыгало
      disableAutoFocus // это нужно чтобы в миниапе не прыгало
      disableRestoreFocus
      allowSwipeInChildren
      keepMounted // это нужно чтобы в миниапе не прыгало
      sx={{
        zIndex: 1099,
        top: { xs: 56, md: 64 },
        "& > .MuiDrawer-paper": {
          bgcolor: theme.palette.background.body,
          position: "absolute",
          top: 0,
          overflowY: "hidden",
        },

        "& > .MuiModal-backdrop": {
          bgcolor: theme.palette.background.body,
        },
      }}
      onTransitionExited={() => setLastMatch(null)}
    >
      <RouteDialogContext.Provider value={contextValue}>
        {lastMatch && (
          <Box
            sx={{ overflowY: "auto" }}
            id={open ? "route-dialog-scrollable" : undefined}
          >
            {clonedElement}
          </Box>
        )}
      </RouteDialogContext.Provider>
    </SwipeableDrawer>
  );
}

RouteDialog.propTypes = {
  path: PropTypes.string.isRequired,
  element: PropTypes.node.isRequired,
};

export function CloseRouteDialogButton({ position = "left" }) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const context = useContext(RouteDialogContext);

  if (isTelegramMiniApp) return null;

  return (
    <StyledIconButton
      size={sm ? "medium" : "medium"}
      onClick={context.onClose}
      sx={{
        left: position === "left" ? 10 : "auto",
        right: position === "right" ? 10 : "auto",
      }}
    >
      <ExpandMoreIcon fontSize={sm ? "large" : "medium"} />
    </StyledIconButton>
  );
}

CloseRouteDialogButton.propTypes = {
  position: PropTypes.oneOf(["left", "right"]),
};
