import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { platforms, getPlatform } from "./Platforms";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Zoom from "@mui/material/Zoom";
import Typography from "@mui/material/Typography";
import {
  IOSShareIcon,
  FireFoxA2HSIcon,
  MenuIcon,
  OperaA2HSIcon,
} from "./Icons";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { useTheme } from "@mui/material/styles";

const PREFIX = "InstallPWAButton";

const classes = {
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  ul: `${PREFIX}-ul`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: "auto",
  // backgroundColor: "rgba(0, 0, 0, 0.2)",

  [`& .${classes.content}`]: {
    padding: theme.spacing(0, 2, 2),
  },

  [`& .${classes.icon}`]: {
    verticalAlign: "middle",
  },

  [`& .${classes.ul}`]: {
    padding: 0,
    paddingLeft: 16,
    margin: 0,
  },
}));

const platform = getPlatform();
// const platform = platforms.NATIVE;
// const platform = platforms.FIREFOX;
// const platform = platforms.FIREFOX_NEW;
// const platform = platforms.OPERA;
// const platform = platforms.IDEVICE;

function logger() {
  // console.log(message);
}

//Track web app install by user
if (process.browser) {
  window.addEventListener("appinstalled", () => {
    ReactGA.event({
      category: "InstallPWAButton",
      action: "PWA app installed by user",
    });
  });
}

export const InstallPWAButton = ({ arena }) => {
  const { t } = useTranslation("arena");
  const deferredprompt = useRef(null);
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const onClickCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!process.browser) return;

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPromptEvent
    );

    return function cleanup() {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPromptEvent
      );
    };
  }, []);

  function isInstalled() {
    if (process.browser) {
      if (
        window.navigator.standalone === true ||
        window.matchMedia("(display-mode: standalone)").matches
      ) {
        logger("isInstalled: true. Already in standalone mode");
        return true;
      }
    }
    logger("isInstalled: false.");
    return false;
  }

  function supported() {
    if (deferredprompt.current !== null && platform === platforms.NATIVE) {
      logger("supported: true - native platform");
      return true;
    }
    if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
      logger("supported: true - manual support");
      return true;
    }
    logger("supported: false");
    return false;
  }

  function handleBeforeInstallPromptEvent(event) {
    event.preventDefault();
    deferredprompt.current = event;
    logger("beforeinstallprompt event fired and captured");
    ReactGA.event({
      category: "InstallPWAButton",
      action: "beforeinstallprompt event fired and captured",
    });
  }

  function onClickInstall() {
    logger("Click to install button");
    ReactGA.event({
      category: "InstallPWAButton",
      action: "Click to install button",
    });
    setOpen(false);
    if (deferredprompt.current !== null) {
      deferredprompt.current
        .prompt()
        .then(() => deferredprompt.current.userChoice)
        .then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            logger("PWA native installation succesful");
            ReactGA.event({
              category: "InstallPWAButton",
              action: "PWA native installation succesful",
            });
          } else {
            logger("User opted out by cancelling native installation");
            ReactGA.event({
              category: "InstallPWAButton",
              action: "User opted out by cancelling native installation",
            });
          }
          return;
        })
        .catch((err) => {
          logger("Error occurred in the installing process: ", err);
        });
    }
  }

  if (!supported()) return null;
  if (isInstalled()) return null;

  const icon = arena.icon?.thumbnails?.find((t) => t.format === "X256")?.url;

  return (
    <Zoom in={open}>
      <StyledCard elevation={0}>
        <CardHeader
          avatar={
            <Avatar
              src={icon}
              alt="logo"
              style={{
                width: 80,
                height: 80,
                borderRadius: 8,
                backgroundColor:
                  theme.palette.mode === "dark" ? "black" : "white",
              }}
            />
          }
          title={t("installPWAButton.title")}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent className={classes.content}>
          {platform === platforms.NATIVE && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button size="small" onClick={onClickCancel}>
                  {t("installPWAButton.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={onClickInstall}
                >
                  {t("installPWAButton.install")}
                </Button>
              </Grid>
            </Grid>
          )}
          {platform === platforms.IDEVICE && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {t("installPWAButton.instructions.title")}
                </Typography>
                <ul className={classes.ul}>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.idevice.action1")}
                    <IOSShareIcon className={classes.icon} />
                  </Typography>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.idevice.action2")}
                  </Typography>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.idevice.action3")}
                  </Typography>
                </ul>
              </Grid>

              <Grid item>
                <Button size="small" onClick={onClickCancel}>
                  {t("installPWAButton.cancel")}
                </Button>
              </Grid>
            </Grid>
          )}
          {platform === platforms.FIREFOX && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  {t("installPWAButton.instructions.title")}
                </Typography>
                <ul className={classes.ul}>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.firefox.action1")}
                    <FireFoxA2HSIcon className={classes.icon} />
                  </Typography>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.firefox.action2")}
                  </Typography>
                </ul>
              </Grid>
              <Grid item>
                <Button size="small" onClick={onClickCancel}>
                  {t("installPWAButton.cancel")}
                </Button>
              </Grid>
            </Grid>
          )}
          {platform === platforms.FIREFOX_NEW && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  {t("installPWAButton.instructions.title")}
                </Typography>
                <ul className={classes.ul}>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.firefoxNew.action1")}
                    <MenuIcon className={classes.icon} />
                  </Typography>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.firefoxNew.action2")}
                  </Typography>
                </ul>
              </Grid>
              <Grid item>
                <Button size="small" onClick={onClickCancel}>
                  {t("installPWAButton.cancel")}
                </Button>
              </Grid>
            </Grid>
          )}
          {platform === platforms.OPERA && (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  {t("installPWAButton.instructions.title")}
                </Typography>
                <ul className={classes.ul}>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.opera.action1")}
                    <MenuIcon className={classes.icon} />
                  </Typography>
                  <Typography variant="body2" component="li">
                    {t("installPWAButton.instructions.opera.action2")}
                    <OperaA2HSIcon className={classes.icon} />
                    {t("installPWAButton.instructions.opera.action3")}
                  </Typography>
                </ul>
              </Grid>
              <Grid item>
                <Button size="small" onClick={onClickCancel}>
                  {t("installPWAButton.cancel")}
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </StyledCard>
    </Zoom>
  );
};

InstallPWAButton.propTypes = {
  arena: PropTypes.object,
};

export default InstallPWAButton;
