import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 6,

  color: "rgb(255, 255, 255);",

  [theme.breakpoints.up("sm")]: {
    padding: 10,
  },

  [theme.breakpoints.up("md")]: {
    padding: 12,
  },
}));

export default function PlayerFooterButton({ className, title, ...props }) {
  return (
    <Tooltip title={title} placement="top-end" arrow>
      <StyledIconButton className={className} {...props} size="large" />
    </Tooltip>
  );
}

PlayerFooterButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
