import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { useQuery } from "@apollo/client";
import { DigitalAssetQuery } from "arena/queries";
import DigitalAssetHelmet from "./DigitalAssetHelmet";
import ErrorScreen from "core/components/ErrorScreen";
import NoMatchScreen from "arena/components/NoMatchScreen";
// import useScrollToTop from "core/hooks/useScrollToTop";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThumbnailCardMedia from "arena/components/ThumbnailCardMedia";
import TagList from "arena/components/TagList";
import { CloseRouteDialogButton } from "arena/components/RouteDialog";

import PaymentButtons from "core/components/PaymentButtons";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useArena } from "core/components/ArenaProvider";
import useProductDialog from "arena/hooks/useProductDialog";
import ProductDialog from "arena/components/ProductDialog";
import Stack from "@mui/material/Stack";

// const Text = styled(Typography)({
//   "& img": {
//     maxWidth: "100%",
//   },
// });

const StyledThumbnailCardMedia = styled(ThumbnailCardMedia)(({ theme }) => ({
  width: "100%",
  position: "relative",
  height: 0,
  paddingTop: "56.25%", // 16:9
  backgroundColor: theme.palette.mode === "dark" ? "black" : "rgba(0,0,0,0.01)",
}));

export default function DigitalAsset({ params: { digitalAssetId } }) {
  const location = useLocation();
  const { t } = useTranslation("arena");
  const arena = useArena();
  const { state, dispatch } = useProductDialog({});

  const onCompleted = (data) => {
    if (data.digitalAsset) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: data.digitalAsset.title,
      });
    }
  };

  const { data, loading, refetch, error } = useQuery(DigitalAssetQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: digitalAssetId,
    },
    onCompleted,
  });

  const digitalAsset = data?.digitalAsset;

  // useScrollToTop();

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!digitalAsset && !loading) {
    return <NoMatchScreen />;
  }

  return (
    <>
      {digitalAsset && <DigitalAssetHelmet digitalAsset={digitalAsset} />}

      {digitalAsset && (
        <Container maxWidth="md" sx={{ p: 0 }}>
          <Card
            sx={{
              backgroundColor: "rgba(255,255,255,0.03)",
              position: "relative",
            }}
          >
            {digitalAsset.preview ? (
              <StyledThumbnailCardMedia
                thumbnails={digitalAsset.preview.thumbnails}
                alt={digitalAsset.title}
                size="MAXRES"
              >
                <CloseRouteDialogButton />
              </StyledThumbnailCardMedia>
            ) : (
              <CloseRouteDialogButton />
            )}

            <CardHeader
              title={digitalAsset.title}
              subheader={t("publishAt", { date: digitalAsset.publishAt })}
              titleTypographyProps={{
                sx: {
                  mb: digitalAsset.preview ? 1 : 2,
                  ml: digitalAsset.preview ? undefined : 7,
                },
              }}
            />

            {digitalAsset.tags.length > 0 && (
              <Box sx={{ pb: 2 }}>
                <TagList tags={digitalAsset.tags} />
              </Box>
            )}

            <CardContent sx={{ pt: 0 }}>
              <Typography
                variant="subheader1"
                color="textPrimary"
                dangerouslySetInnerHTML={{
                  __html:
                    digitalAsset.premiumDescription || digitalAsset.description,
                }}
              />

              {digitalAsset.premium && !digitalAsset.viewerPurchase ? (
                <Box sx={{ m: 2, p: 2 }}>
                  <ErrorScreen
                    title={t("paidContent.title")}
                    text={t("paidContent.description")}
                    icon={PaymentsIcon}
                    button={
                      <PaymentButtons
                        products={digitalAsset.products.nodes.filter(
                          (product) => product.prices.length
                        )}
                        isTelegramArena={arena?.isTelegramArena}
                        onClickProductButton={dispatch}
                      />
                    }
                  />
                </Box>
              ) : (
                <Stack sx={{ mb: 2, p: 2 }} alignContent={"center"} spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href={digitalAsset.privateFile.url}
                    // fullWidth
                    size="large"
                  >
                    {t("digitalAsset.download")}
                  </Button>
                </Stack>
              )}
            </CardContent>
          </Card>
          <ProductDialog
            products={digitalAsset.products.nodes}
            state={state}
            dispatch={dispatch}
            refetch={refetch}
          />
        </Container>
      )}
    </>
  );
}

DigitalAsset.propTypes = {
  params: PropTypes.shape({
    digitalAssetId: PropTypes.string.isRequired,
  }),
};
