import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ProductCarouselPrevButton from "./ProductCarouselPrevButton";
import ProductCarouselNextButton from "./ProductCarouselNextButton";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import SwipeableViews from "react-swipeable-views";
import chunk from "core/utils/chunk";
import ProductCarouselItem from "arena/components/ProductCarouselItem";

const PREFIX = "ProductCarousel";

const classes = {
  root: `${PREFIX}-root`,
  rootServer: `${PREFIX}-rootServer`,
  swipeableViews: `${PREFIX}-swipeableViews`,
  group: `${PREFIX}-group`,
  item: `${PREFIX}-item`,
  grid: `${PREFIX}-grid`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",

  [theme.breakpoints.only("xs")]: {
    padding: theme.spacing(0, 2),
  },

  [`& .${classes.rootServer}`]: {},

  [`& .${classes.swipeableViews}`]: {
    // margin: theme.spacing(-0.5, -1.5),
    // padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      // margin: theme.spacing(0, -3),
      // padding: theme.spacing(0, 1.5),
    },
  },

  [`& .${classes.group}`]: {
    overflow: "hidden",
    // padding: theme.spacing(0, 1.5),
  },

  [`& .${classes.grid}`]: {
    flexWrap: "nowrap",
  },

  [`& .${classes.item}`]: {
    flexShrink: "0",
  },
}));

export default function ProductCarousel({
  nodes,
  // count,
  // arenaId,
  // type,
  // rowsPerPage,
  xs,
  sm,
  md,
  lg,
  xl,
  // empty,
  // fetchPolicy,
  title,
  // rightholderIds,
  // loadMore,
}) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));

  // const isMobile = isXs;

  const [index, setIndex] = useState(0);

  const onChangeIndex = (index) => {
    setIndex(index);
  };

  const onClickPromoVideoButton = () => {};

  if (process.browser) {
    let groups = [];
    let size = 1; // сколько элементов в группе

    if (isXs) {
      size = 12 / xs;
    } else if (isSm) {
      size = 12 / sm;
    } else if (isMd) {
      size = 12 / md;
    } else if (isLg) {
      size = 12 / lg;
    } else if (isXl) {
      size = 12 / xl;
    }

    groups = chunk(nodes, size);

    const prevDisabled = index === 0;
    const nextDisabled = groups.length === index + 1;

    const onClickPrev = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => index - 1 || 0);
    };

    const onClickNext = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => {
        if (index + 1 >= groups.length) {
          return 0;
        }
        return index + 1;
      });
    };

    return (
      <>
        {title}

        <Root test-id="ProductCarousel">
          <ProductCarouselPrevButton
            disabled={prevDisabled}
            onClick={onClickPrev}
            size={size}
          />

          <ProductCarouselNextButton
            disabled={nextDisabled}
            onClick={onClickNext}
            size={size}
          />

          <SwipeableViews
            disableLazyLoading={true}
            className={classes.swipeableViews}
            index={index}
            onChangeIndex={onChangeIndex}
          >
            {groups.map((group, index) => (
              <div className={classes.group} key={index}>
                <Grid container spacing={3}>
                  {group.map((product) => (
                    <Grid
                      item
                      xs={xs}
                      sm={sm}
                      md={md}
                      lg={lg}
                      xl={xl}
                      key={product.id}
                      className={classes.item}
                    >
                      <ProductCarouselItem
                        cardView="COMPACT"
                        product={product}
                        onClickPromoVideoButton={onClickPromoVideoButton}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </SwipeableViews>
        </Root>
      </>
    );
  }

  return (
    <>
      {title}

      <Root test-id="ProductCarousel">
        <Grid container spacing={3} className={classes.grid}>
          {nodes.map((product) => (
            <Grid
              item
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              xl={xl}
              key={product.id}
              className={classes.item}
            >
              <ProductCarouselItem
                cardView="COMPACT"
                product={product}
                onClickPromoVideoButton={onClickPromoVideoButton}
              />
            </Grid>
          ))}
        </Grid>
      </Root>
    </>
  );
}

ProductCarousel.defaultProps = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 4,
  xl: 4,
  nodes: [],
};

ProductCarousel.propTypes = {
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xl: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  collectionId: PropTypes.string,
  productId: PropTypes.string,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  count: PropTypes.number,
  title: PropTypes.node,
};
