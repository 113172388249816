import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js";
import { CompletePaymentMutation } from "arena/mutations";
import { useMutation } from "@apollo/client";
import { withSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import ReactGA from "react-ga4";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

function PaypalCheckout({
  paymentId,
  enqueueSnackbar,
  setError,
  setLoading,
  paymentData,
  loading,
  descriptionComponent,
  paymentHasError,
  setPaymentHasError,
}) {
  const theme = useTheme();
  const style = {
    layout: "vertical",
    color: theme.palette.mode === "dark" ? "black" : "white",
    label: "pay",
  };

  const [{ isPending }] = usePayPalScriptReducer();
  const [paypalError, setPaypalError] = useState(false);
  const [paypalErrorDescription, setPaypalErrorDescription] = useState();

  useEffect(() => {
    setLoading(isPending);
  }, [isPending]);

  const { t } = useTranslation("arena");

  const [completePayment] = useMutation(CompletePaymentMutation, {
    onError(error) {
      setLoading(false);
      setError(error);
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const createOrder = (data, actions) => {
    setPaypalError(false);
    setPaypalErrorDescription(false);

    ReactGA.event({
      category: "Product",
      action: "Start pay",
      label: "paypal",
    });

    return actions.order.create(paymentData.orderData);
  };

  const createSubscription = (data, actions) => {
    setPaypalError(false);

    ReactGA.event({
      category: "Product",
      action: "Start pay",
      label: "paypal",
    });

    return actions.subscription.create(paymentData.subscriptionData);
  };

  const emitSuccessEvent = () => {
    if (paymentHasError) {
      ReactGA.event({
        category: "Product",
        action: "Success pay after error",
        label: "paypal",
      });
    } else {
      ReactGA.event({
        category: "Product",
        action: "Success pay",
        label: "paypal",
      });
    }
  };

  const onApprove = (data, actions) => {
    setLoading(true);
    setPaypalError(false);
    setPaypalErrorDescription(false);

    ReactGA.event({
      category: "Product",
      action: "Start complete payment",
      label: "paypal",
    });

    completePayment({
      variables: {
        id: paymentId,
        input: { paypalData: data },
      },
    })
      .then((data) => {
        const payment = data.data.completePayment;

        /**
         * Платеж может прилететь с задержкой
         */
        if (payment.status === "PENDING") {
          if (!payment.completeError) {
            emitSuccessEvent();

            enqueueSnackbar(t("paypalPaymentForm.paymentCompleted"), {
              variant: "success",
            });

            window.location = payment.returnUrl;

            return;
          }
        }

        if (payment.status === "SUCCEEDED") {
          emitSuccessEvent();

          enqueueSnackbar(t("paypalPaymentForm.paymentCompleted"), {
            variant: "success",
          });

          window.location = payment.returnUrl;

          return;
        }

        if (payment.completeError) {
          const errorDetail =
            Array.isArray(payment.completeError.details) &&
            payment.completeError.details[0];

          if (errorDetail) {
            if (errorDetail.issue === "INSTRUMENT_DECLINED") {
              ReactGA.event({
                category: "Product",
                action: "Restart pay",
                label: "paypal",
              });

              return actions.restart();
            } else {
              ReactGA.event({
                category: "Product",
                action: "Pay error",
                label: "paypal",
              });
              setPaymentHasError(true);

              setPaypalErrorDescription(
                `${errorDetail.description} (${payment.completeError.debug_id})`
              );
            }
          }
        }

        setLoading(false);
        setPaypalError(true);

        enqueueSnackbar(t("paypalPaymentForm.completeError"), {
          variant: "error",
          autoHideDuration: 3000,
        });

        return;
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  };

  const handlers = {
    onApprove,
  };

  if (paymentData.subscriptionData) {
    handlers.createSubscription = createSubscription;
  } else if (paymentData.orderData) {
    handlers.createOrder = createOrder;
  }

  const onPaypalError = (err) => {
    setPaymentHasError(true);
    ReactGA.event({
      category: "Product",
      action: "Pay error",
      label: "paypal",
    });
    console.log("paypal error", err);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {paypalError && (
          <Grid item xs={12}>
            <Alert severity="error">
              {paypalErrorDescription || t("paypalPaymentForm.paymentError")}
            </Alert>
          </Grid>
        )}

        {descriptionComponent && (
          <Grid item xs={12}>
            {descriptionComponent}
          </Grid>
        )}

        <Grid item xs={12}>
          <PayPalButtons
            style={style}
            disabled={loading}
            showSpinner={loading}
            onError={onPaypalError}
            fundingSource="paypal"
            {...handlers}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

PaypalCheckout.propTypes = {
  paymentHasError: PropTypes.bool,
  setPaymentHasError: PropTypes.func.isRequired,
  descriptionComponent: PropTypes.object,
  paymentId: PropTypes.string.isRequired,
  paymentData: PropTypes.shape({
    amount: PropTypes.shape({
      value: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
    }),
    orderData: PropTypes.object,
    subscriptionData: PropTypes.object,
  }).isRequired,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  setError: PropTypes.func,
  returnUrl: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(PaypalCheckout);
