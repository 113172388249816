import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ProductPlayer from "arena/components/ProductPlayer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = "ProductPromoVideoDialog";

const classes = {
  root: `${PREFIX}-root`,
  player: `${PREFIX}-player`,
  close: `${PREFIX}-close`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.player}`]: {
    zIndex: 1,
    position: "relative",
    height: 0,
    paddingTop: "56.25%",
    background: theme.palette.mode === "dark" ? "black" : "white",
    overflow: "hidden",

    "&:fullscreen": {
      paddingTop: 0,
    },

    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
      height: "100%",
    },
  },

  [`& .${classes.close}`]: {
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductPromoVideoDialog({ product, open, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledDialog
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <div id="product-player" className={classes.player}>
        <ProductPlayer
          getIpUrl={product.getIpUrl}
          fullscreenTarget="product-player"
          productId={product.id}
        />
      </div>
      <Fab className={classes.close} size="small" onClick={onClose}>
        <CloseIcon />
      </Fab>
    </StyledDialog>
  );
}

ProductPromoVideoDialog.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    getIpUrl: PropTypes.string,
  }).isRequired,
  getIpUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
