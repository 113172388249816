import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UpdateSubscriptionMutation } from "core/mutations";
import { withSnackbar } from "notistack";
import { useViewer } from "core/components/ViewerProvider";
import classNames from "classnames";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsIcon from "@mui/icons-material/Notifications";

const PREFIX = "SubscribeStreamButton";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  iconButton: `${PREFIX}-iconButton`,
  iconButtonActive: `${PREFIX}-iconButtonActive`,
  active: `${PREFIX}-active`,
};

const Root = styled("div")(({ theme }) => {
  const iconButtonColor =
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,0.9)";

  const activeColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,1)" : "rgba(0,0,0,0.1)";

  return {
    display: "flex",
    alignItems: "center",

    [`& .${classes.button}`]: {
      whiteSpace: "nowrap",
    },

    [`& .${classes.iconButton}`]: {
      color: "white",
      backgroundColor: iconButtonColor,
      "&:hover": {
        backgroundColor: iconButtonColor,
      },
      "&:focus": {
        backgroundColor: iconButtonColor,
      },
      "&:active": {
        backgroundColor: iconButtonColor,
      },
    },

    [`& .${classes.iconButtonActive}`]: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:focus": {
        backgroundColor: theme.palette.primary.main,
      },
      "&:active": {
        backgroundColor: theme.palette.primary.main,
      },
    },

    [`& .${classes.active}`]: {
      backgroundColor: activeColor,
      // color: activeColor,
      "&:hover": {
        backgroundColor: activeColor,
      },
      "&:focus": {
        backgroundColor: activeColor,
      },
      "&:active": {
        backgroundColor: activeColor,
      },
    },
  };
});

const CONTENT = "CONTENT";

function SubscribeStreamButton({ enqueueSnackbar, content, className, size }) {
  const { t } = useTranslation("core");
  const viewer = useViewer();

  const [updateSubscription, { loading, data }] = useMutation(
    UpdateSubscriptionMutation,
    {
      onError(error) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const contentId = content.id;

  const viewerSubscription =
    data?.updateSubscription || content?.viewerSubscription;

  const active = viewerSubscription?.active;

  const onClickSubscribeButton = (event) => {
    event.preventDefault();

    if (viewer) {
      return updateSubscription({
        variables: {
          input: {
            type: CONTENT,
            contentId,
            active: active ? false : true,
            notify: active ? false : true,
          },
        },
      }).then(({ data }) => {
        const { active } = data.updateSubscription;

        if (active) {
          return enqueueSnackbar(t("subscribeStreamButton.subscriptionAdded"), {
            variant: "success",
            preventDuplicate: true,
          });
        } else {
          return enqueueSnackbar(
            t("subscribeStreamButton.subscriptionRemoved"),
            {
              variant: "success",
              preventDuplicate: true,
            }
          );
        }
      });
    } else {
      document.querySelector(".js-sign-in").click();
    }
  };

  const text = active
    ? t("subscribeStreamButton.subscribed")
    : t("subscribeStreamButton.subscribe");

  const color = active ? "inherit" : "primary";

  return (
    <Root className={className}>
      <Tooltip
        title={
          active
            ? t("subscribeStreamButton.subscribedTitle")
            : t("subscribeStreamButton.subscribeTitle")
        }
      >
        <span>
          {size === "small" ? (
            <IconButton
              disabled={loading}
              onClick={onClickSubscribeButton}
              size="small"
              classes={{
                root: classNames(classes.iconButton, {
                  [classes.iconButtonActive]: active,
                }),
              }}
            >
              {active ? (
                <NotificationsActiveIcon fontSize="small" />
              ) : (
                <NotificationsIcon fontSize="small" />
              )}
            </IconButton>
          ) : (
            <Button
              disableElevation
              className={classNames(classes.button, {
                [classes.active]: active,
              })}
              color={color}
              variant="contained"
              disabled={loading}
              onClick={onClickSubscribeButton}
            >
              {text}
            </Button>
          )}
        </span>
      </Tooltip>
    </Root>
  );
}

SubscribeStreamButton.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    viewerSubscription: PropTypes.shape({
      notify: PropTypes.bool,
      active: PropTypes.bool,
    }),
  }),
};

export default withSnackbar(SubscribeStreamButton);
